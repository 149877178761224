import * as React from 'react'
import {Button, PRIORITY} from 'wix-ui-tpa/Button'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {classes} from './buttons.st.css'
import {ButtonsProps} from '.'

export const Buttons = ({selectedTickets, onTicketsClick, onCheckoutClick}: ButtonsProps) => {
  const {t} = useTranslation()

  return (
    <div className={classes.buttons}>
      {onTicketsClick ? (
        <Button className={classes.button} priority={PRIORITY.basicSecondary} onClick={onTicketsClick}>
          {t('seatings_tickets', {count: selectedTickets})}
        </Button>
      ) : null}
      {onCheckoutClick ? (
        <Button
          className={classNames(classes.button, !onTicketsClick ? classes.fullWidth : undefined)}
          disabled={!selectedTickets}
          onClick={onCheckoutClick}
        >
          {t('seatings_checkout')}
        </Button>
      ) : null}
    </div>
  )
}
