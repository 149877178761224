import * as React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import s from './label-with-value.scss'
import {LabelWithValueProps} from '.'

export const LabelWithValue = ({label, value}: LabelWithValueProps) =>
  value ? (
    <div className={s.labelWithValue}>
      <Text className={s.smallText}>{label}</Text>
      <Text className={s.value}>{value}</Text>
    </div>
  ) : null
