import * as React from 'react'
import {Tickets} from '../tickets'
import {Plan} from '../plan'
import {Footer} from '../footer'
import {AccessibilityMode} from '../accessibility-mode'
import s from './mobile.scss'
import {MobileProps} from '.'

export const Mobile = ({
  selectedSeats,
  selectedSeat,
  plan,
  onRemoveClick,
  onCheckoutClick,
  onSeatClick,
  onAddClick,
  onCloseClick,
  showAccessibilityMode,
}: MobileProps) => {
  const [showTickets, setShowTickets] = React.useState(false)

  return (
    <div className={s.container}>
      <Tickets
        selectedSeats={selectedSeats}
        showTickets={showTickets}
        onBackClick={() => setShowTickets(false)}
        onRemoveClick={onRemoveClick}
        onCheckoutClick={onCheckoutClick}
      />
      {showAccessibilityMode ? <AccessibilityMode /> : null}
      <Plan selectedSeat={selectedSeat} selectedSeats={selectedSeats} plan={plan} onSeatClick={onSeatClick} />
      <Footer
        selectedSeat={selectedSeat}
        onAddClick={onAddClick}
        onCloseClick={onCloseClick}
        onTicketsClick={() => setShowTickets(true)}
        onCheckoutClick={onCheckoutClick}
      />
    </div>
  )
}
