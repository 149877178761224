import * as React from 'react'
import {Spinner} from 'wix-ui-tpa/Spinner'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import {classes} from './loader.st.css'

export const Loader = () => {
  const {t} = useTranslation()

  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <Text className={classes.title}>{t('seatings_loader_title')}</Text>
        <Text className={classes.subtitle}>{t('seatings_loader_subtitle')}</Text>
      </div>
      <Spinner className={classes.spinner} />
    </div>
  )
}
