import * as React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import BulletedListSmall from 'wix-ui-icons-common/on-stage/BulletedListSmall'
import {TextButton, TEXT_BUTTON_PRIORITY} from 'wix-ui-tpa/TextButton'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {Divider} from '../../common/divider'
import {classes} from './accessibility-mode-button.st.css'
import {AccessibilityModeButtonProps} from '.'

export const AccessibilityModeButton = ({setShowAccessibilityMode}: AccessibilityModeButtonProps) => {
  const {isMobile} = useEnvironment()

  return (
    <div className={classNames(classes.accessibilityModeContainer, {[classes.desktop]: !isMobile})}>
      <TextButton
        className={classes.textButton}
        data-hook={DH.ACCESSIBILITY_MODE_BUTTON}
        priority={TEXT_BUTTON_PRIORITY.secondary}
        prefixIcon={<BulletedListSmall />}
        onClick={() => setShowAccessibilityMode(true)}
      >
        Select from list
      </TextButton>
      {isMobile ? <Divider className={classes.divider} /> : null}
    </div>
  )
}
