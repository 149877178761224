import * as React from 'react'
import {Button} from 'wix-ui-tpa/Button'
import {IconButton} from 'wix-ui-tpa/IconButton'
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Checkbox} from 'wix-ui-tpa/Checkbox'
import {Text} from 'wix-ui-tpa/Text'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {LabelWithValue} from '../../../common/label-with-value'
import {classes} from './ticket-info.st.css'
import {TicketInfoProps} from '.'

export const TicketInfo = ({
  elementType,
  withCheckbox,
  selected,
  sector,
  seat,
  row,
  ticketName,
  ticketPrice,
  fees,
  onAddClick,
  onCloseClick,
  onRemoveClick,
}: TicketInfoProps) => {
  const {t} = useTranslation()
  const area = elementType === Type.AREA

  return (
    <div className={classes.container} aria-hidden={true}>
      {withCheckbox ? <Checkbox disabled className={classes.checkbox} checked={selected} onChange={undefined} /> : null}
      {onCloseClick ? (
        <IconButton className={classes.headerButton} icon={<CloseSmall />} onClick={onCloseClick} />
      ) : null}
      {onRemoveClick ? (
        <IconButton className={classes.headerButton} icon={<DeleteSmall />} onClick={onRemoveClick} />
      ) : null}
      <div className={classes.infoContainer}>
        <LabelWithValue label={t('seatings_sector')} value={sector} />
        <LabelWithValue label={area ? 'Area' : t('seatings_row')} value={row} />
        {area ? null : <LabelWithValue label={t('seatings_seat')} value={seat} />}
      </div>
      <div className={classes.ticketInfo}>
        <Text className={classes.smallText}>{ticketName}</Text>
        <Text className={classes.ticketPrice}>{ticketPrice}</Text>
        <Text className={classes.smallText}>{fees}</Text>
      </div>
      {onAddClick ? (
        <Button onClick={onAddClick} data-hook={DH.ADD}>
          {t('seatings_add')}
        </Button>
      ) : null}
    </div>
  )
}
