import * as React from 'react'
import {Summary} from '../summary'
import {TicketInfo} from '../ticket-info'
import {AccessibilityModeButton} from '../accessibility-mode-button'
import s from './footer.scss'
import {FooterProps} from '.'

export const Footer = ({selectedSeat, onAddClick, onCloseClick, onTicketsClick, onCheckoutClick}: FooterProps) => (
  <>
    <AccessibilityModeButton />
    <div className={s.footer}>
      {selectedSeat ? (
        <TicketInfo {...selectedSeat} onAddClick={onAddClick} onCloseClick={onCloseClick} />
      ) : (
        <Summary onTicketsClick={onTicketsClick} onCheckoutClick={onCheckoutClick} />
      )}
    </div>
  </>
)
