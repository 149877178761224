import {Place, Element, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {WIX_EVENTS_TICKET_DEFINITION_FQDN} from '@wix/wix-events-commons-statics'
import React, {useState, useCallback} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Modal} from '../modal'
import {getSelectedTicketQuantity} from '../../../selectors/selected-tickets'
import {getSeatWithTicketInfo} from '../../../selectors/seating'
import {SelectedSeat} from '../../../types/seating'
import {Loader} from './loader'
import {Mobile} from './mobile'
import s from './seating-plan.scss'
import {Desktop} from './desktop'
import {SeatingPlanProps} from '.'

export const SeatingPlan = ({
  plan,
  tickets,
  selectTicket,
  unselectTicket,
  selectedTickets,
  checkout,
  loading,
  event,
  selectedSeats,
  selectSeat,
  unselectSeat,
}: SeatingPlanProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  const [selectedSeat, setSelectedSeat] = useState<SelectedSeat>(null)

  const handleAddClick = useCallback(() => {
    const ticketId = selectedSeat.ticketId

    selectSeat(selectedSeat)
    selectTicket({
      ticketId,
      count: getSelectedTicketQuantity(selectedTickets, ticketId) + 1,
      seatId: selectedSeat.id,
    })
    setSelectedSeat(null)
  }, [selectSeat, selectTicket, selectedSeat, selectedTickets])

  React.useEffect(() => {
    if (!isMobile && selectedSeat) {
      handleAddClick()
    }
  }, [selectedSeat, isMobile, handleAddClick])

  const handleCheckout = () => checkout()

  const handleRemove = (id: string, ticketId: string) => {
    unselectTicket({ticketId, count: getSelectedTicketQuantity(selectedTickets, ticketId) - 1, seatId: id})
    unselectSeat(id)
  }

  const onSeatClick = (seat: Place, categoryId: number, definition: Element) => {
    const {externalId} = plan.categories.find(category => category.id === categoryId)
    const ticketDefinition = tickets.find(
      ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''),
    )
    const area = seat.elementType === Type.AREA

    if (!area && selectedSeats.find(selected => selected.id === seat.id)) {
      return handleRemove(seat.id, ticketDefinition.id)
    }

    setSelectedSeat(getSeatWithTicketInfo({plan, event, seat, categoryId, t, tickets}))
  }

  const component = isMobile ? (
    <Mobile
      selectedSeat={selectedSeat}
      selectedSeats={selectedSeats}
      plan={plan}
      onRemoveClick={handleRemove}
      onCheckoutClick={handleCheckout}
      onSeatClick={onSeatClick}
      onCloseClick={() => setSelectedSeat(null)}
      onAddClick={handleAddClick}
    />
  ) : (
    <Desktop
      selectedSeat={selectedSeat}
      selectedSeats={selectedSeats}
      plan={plan}
      onSeatClick={onSeatClick}
      onRemoveClick={handleRemove}
      onCheckoutClick={handleCheckout}
    />
  )

  return (
    <Modal
      className={!isMobile ? s.desktopModal : undefined}
      skin={loading ? undefined : 'grey'}
      closeButtonAsButton={!loading}
      dataHook={DH.SEATINGS_MODAL}
    >
      {loading ? <Loader /> : component}
    </Modal>
  )
}
