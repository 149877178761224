import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import {classes} from './empty-state.st.css'

export const EmptyState = () => {
  const {t} = useTranslation()

  return (
    <div className={classes.container} tabIndex={0}>
      <Text className={classes.title}>{t('seatings_tickets_emptyState_title')}</Text>
      <Text className={classes.subtitle}>{t('seatings_tickets_emptyState_description')}</Text>
    </div>
  )
}
