import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import {Summary} from '../summary'
import {EventInfo} from '../event-info'
import {SlideInModal} from '../../slide-in-modal'
import {TicketsListContainer} from '../tickets-list-container'
import {Ticket} from '../ticket'
import {SummaryContainer} from '../summary-container'
import {AccessibilityModeButton} from '../accessibility-mode-button'
import {classes} from './tickets.st.css'
import {EmptyState} from './empty-state'
import {TicketsProps} from '.'

export const Tickets = ({showTickets, selectedSeats, onBackClick, onRemoveClick, onCheckoutClick}: TicketsProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const desktop = !isMobile

  return (
    <SlideInModal show={showTickets} onBackClick={onBackClick}>
      {isMobile && !selectedSeats.length ? (
        <EmptyState />
      ) : (
        <>
          <EventInfo />
          <Text className={classes.title}>{t('seatings_yourTickets', {count: selectedSeats.length})}</Text>
          {desktop && !selectedSeats.length ? (
            <Text className={classes.subtitle}>You can select your seats by clicking on the seating plan.</Text>
          ) : null}
          <TicketsListContainer>
            {selectedSeats.map((selectedTicket, index) => (
              <Ticket
                key={selectedTicket.id}
                firstTicket={index === 0}
                lastTicket={index === selectedSeats.length - 1}
                {...selectedTicket}
                onRemoveClick={onRemoveClick}
              />
            ))}
          </TicketsListContainer>
        </>
      )}
      {desktop || selectedSeats.length ? (
        <>
          {desktop ? <AccessibilityModeButton /> : null}
          <SummaryContainer>
            <Summary onCheckoutClick={onCheckoutClick} />
          </SummaryContainer>
        </>
      ) : null}
    </SlideInModal>
  )
}
